import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

const initSentry = async () => {
  const response = await fetch("/api/sentry", {
    method: "POST",
  });

  let environment = "development";

  if (response.status === 200) {
    const body = await response.json();

    environment = body.environment;
  }

  Sentry.init({
    dsn: "https://c6a5a33acce540173525c4a9edbe8902@o4507463480311808.ingest.de.sentry.io/4507525948833872",
    tracesSampleRate: 1.0,
    environment,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
  });
};

initSentry();
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
